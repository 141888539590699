.mid-react__LoginPage {
  background: #051c2c;
  background: linear-gradient(135deg, #051c2c 40%,#03339c 100%);
  background: -webkit-linear-gradient(-45deg, #051c2c 40%, #03339c 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mid-react__LoginPage .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f8f8f8;
  box-shadow: 0 4px 8px -1px rgba(5, 28, 44, 0.2), 0 0 1px 0 rgba(5, 28, 44, 0.15);
  padding: 25px;
  margin: 50px;
  min-width: 30%;
}

.mid-react__LoginPage .content .error-msg {
  color: #E5546C
}

.mid-react__spinner {
  margin: auto;
  width: 70px;
  text-align: center;
}

.mid-react__spinner > div {
  width: 18px;
  height: 18px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.mid-react__spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.mid-react__spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}